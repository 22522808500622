import { Button, Icon, IconNames } from 'og-merchant-portal-react-library';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import DeviceScreenMaxWidth from '../../common/helpers/deviceScreenSizeHelper';
import ROUTES from '../../common/helpers/routes';

const StyledContainerDiv = styled.div`
  max-width: 646px;
  margin: 50px;
  background: ${props => props.theme.colors.greyScale.white};
  border-radius: 16px;
  filter: drop-shadow(0px 3px 14px rgba(78, 71, 69, 0.3));
  padding: 40px 64px;
  font-size: 16px;

  @media screen and (max-width: ${DeviceScreenMaxWidth.mobile}) {
    padding: 48px 32px;
  }

  button {
    width: 50%;
    padding: 9px 0;
    margin: 80px 100px 0px 100px;
    font-size: 18px;
  }
`;

const StyledTitle = styled.h1`
  text-align: center;
  font-weight: ${props => props.theme.font.weights.medium};
  font-size: 28px;
`;

const StyledEmailIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  path {
    fill: ${props => props.theme.colors.primary.regular};
  }
`;

const StyledMainContentContainer = styled.div``;
const StyledParagraph = styled.p``;

const StyledInlineTitle = styled.p`
  margin-bottom: 5px;
  font-weight: ${props => props.theme.font.weights.semibold};
`;

const StyledFinalParagraph = styled.p`
  margin-top: 0;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const SuccessPage = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const theme = useContext(ThemeContext);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!location.state) {
      navigation(ROUTES.home);
      return undefined;
    }
  }, [location, navigation]);

  const emailAddress = location?.state?.emailAddress;

  const openIntegrationUrl = () => {
    const { integrationSupportUrl } = window.appSettings;
    window.open(integrationSupportUrl, '_blank');
  };

  return (
    <StyledContainerDiv data-testid="styled_container_div">
      <StyledTitle data-testid="styled_title">
        <FormattedMessage id="developer.success.page.title" defaultMessage="Check your email!" />
      </StyledTitle>
      <StyledEmailIcon data-testid="styled_emailIcon">
        <Icon name={IconNames.mail} size={40} color={theme.colors.primary.regular} />
      </StyledEmailIcon>
      <StyledParagraph data-testid="styled_first_paragraph">
        <FormattedMessage id="developer.success.page.check.email.warning" defaultMessage="Check" />
        {` ${emailAddress} `}
        <FormattedMessage
          id="developer.success.page.check.email.address"
          defaultMessage="for a link to set your password. This help us keep
					your account secure."
        />
      </StyledParagraph>
      <StyledParagraph data-testid="styled_second_paragraph">
        <FormattedMessage
          id="developer.success.page.email.spam.warning"
          defaultMessage="If you cannot see the email please check your spam folder."
        />
      </StyledParagraph>
      <StyledMainContentContainer data-testid="styled_main_content_container">
        <StyledInlineTitle data-testid="styled_inline_title">
          <FormattedMessage
            id="developer.success.page.next.step.message"
            defaultMessage="What’s next?"
          />
        </StyledInlineTitle>
        <StyledFinalParagraph data-testid="styled_third_paragraph">
          <FormattedMessage
            id="developer.success.page.secure.account.message"
            defaultMessage="After you secure your test account, you can build a test integration. The first step is deciding how to link your website with our platform."
          />
        </StyledFinalParagraph>
      </StyledMainContentContainer>
      <StyledButtonContainer data-testid="button_container">
        <Button data-test="choose_integration_button" role="button" onClick={openIntegrationUrl}>
          <FormattedMessage
            id="developer.success.page.integration.button"
            defaultMessage="Choose your integration"
          />
        </Button>
      </StyledButtonContainer>
      <StyledLink data-testid="styled_link">
        <Link data-testid="link" to={ROUTES.home} style={{ color: 'black' }}>
          <FormattedMessage
            id="developer.success.page.create.account.button"
            defaultMessage="Create another test account"
          />
        </Link>
      </StyledLink>
    </StyledContainerDiv>
  );
};

export default SuccessPage;
